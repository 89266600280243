import * as yup from "yup"
import { useTranslation } from "react-i18next";

const ValidationSchema = () => {
  const { t } = useTranslation();

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = yup.object().shape({
    lastName: yup
      .string()
      .max(32, t("trebuie să fie de maxim 32 caractere"))
      .required(t("Vă rugăm introduceți numele de familie")),
    firstName: yup
      .string()
      .max(32, t("trebuie să fie de maxim 32 caractere"))
      .required(t("Vă rugăm introduceți prenumele")),
    email: yup
      .string()
      .max(62, t("trebuie să fie de maxim 62 caractere"))
      .email(t("Formatul adresei de email e invalid"))
      .required(t("Vă rugăm introduceți adresa de email")),
    companyName: yup
      .string()
      .max(60, t("trebuie să fie de maxim 60 caractere"))
      .required(t("Introduceți denumirea societății")),
    CUI: yup
      .string()
      .matches(/^\d+$/, t("Introduceți doar numere!"))
      .required(t("Introduceți CUI")),
    jobPosition: yup
      .string()
      .max(32, t("trebuie să fie de maxim 32 caractere"))
      .required(t("Vă rugăm completați funcția dumneavoastră")),
    recaptcha: yup.string().nullable().required(t("Vă rugăm validați verificarea")),
    phone: yup
      .string()
      .max(16, t("trebuie să fie de maxim 16 caractere"))
      .matches(phoneRegExp, t("Număr de telefon invalid"))
      .required(t("Vă rugăm introduceți un număr de telefon")),
    address: yup
      .string()
      .max(255, t("trebuie să fie de maxim 255 caractere"))
      .required(t("Vă rugăm completați adresa societății")),
    kWh: yup
      .string()
      .max(12, t("Trebuie să fie de maxim 12 caractere"))
      .required(t("Vă rugăm introduceți consumul")),
    assembly: yup.object().required(t("Vă rugăm selectați amplasarea panourilor")),
    seasonality: yup.string().required(t("Vă rugăm introduceți sezonabilitatea")),
  })

  return validationSchema;
}

export default ValidationSchema
