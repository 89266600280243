import React from "react"
import { ToastContainer } from "react-toastify"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import Header from "../components/Header/Header"
import ElectricUp from "../components/ElectricUp/ElectricUp"
import Footer from "../components/Footer/Footer"
import "react-toastify/dist/ReactToastify.css"

const ElectricUpPage = () => {
  return (
    <Layout>
      <Header />
      <ElectricUp />
      <Footer />
      <ToastContainer />
    </Layout>
  )
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
export default ElectricUpPage
